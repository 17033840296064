import React from "react"
import { graphql } from "gatsby"

import PostList from "../components/post-list"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Component = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const postsPage = data.postsPage

  const posts = data.posts.nodes.map(p => ({
    ...p,
    ...p.metadata,
    path: p.fields.path,
    published: {
      iso: p.metadata.published,
      pretty: p.metadata.publishedPretty,
    },
    modified: { iso: p.metadata.modified, pretty: p.metadata.modifiedPretty },
  }))

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Posts" />
      <div className="[ content ]">
        <div className="[ heading ]">
          <div className="[ title-wrapper ]">
            <h1 className="[ title ]">{postsPage.metadata.title}</h1>
          </div>
        </div>
        <div
          className="[ caption ]"
          dangerouslySetInnerHTML={{ __html: postsPage.html }}
        />
        <PostList posts={posts} />
      </div>
    </Layout>
  )
}

export default Component

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    postsPage: orgContent(metadata: { title: { eq: "posts" } }) {
      html
      metadata {
        title
      }
    }

    posts: allOrgContent(
      sort: { fields: [metadata___published], order: DESC }
      filter: { metadata: { published: { ne: null } } }
    ) {
      nodes {
        fields {
          path
        }
        metadata {
          title
          description
          publishedPretty: published(formatString: "MMMM DD, YYYY")
          published
          modifiedPretty: modified(formatString: "HH:mm, MMMM DD, YYYY")
          modified
          tags
        }
      }
    }
  }
`
